export default [
  {
    prop: 'id',
    label: 'ID',
    minWidth: 100,
    type: 'id'
  },
  {
    prop: 'name',
    label: 'Tên',
    minWidth: 180,
    type: 'text'
  },
  {
    prop: 'race_name',
    label: 'Race',
    minWidth: 180,
    type: 'text'
  },
  {
    prop: 'status',
    label: 'Trạng thái',
    minWidth: 180,
    type: 'select',
    options: [{
        value: 0,
        title: 'Chưa thanh toán'
      },
      {
        value: 1,
        title: 'Đã thanh toán'
      }
    ]
  },
  {
    prop: 'email',
    label: 'Email',
    minWidth: 220,
    type: 'text'
  },
  {
    prop: 'phone',
    label: 'SĐT',
    minWidth: 130,
    type: 'text'
  },
  {
    prop: 'pay_gate_name',
    label: 'Paygate',
    minWidth: 100,
    type: 'select',
    options: [{
      value: 0,
      title: 'PayPal'
    },
    {
      value: 1,
      title: 'OnePay'
    },
    {
      value: 2,
      title: 'ZaloPay'
    },
    {
      value: 3,
      title: 'MoMo'
    },
    {
      value: 4,
      title: 'Chuyển khoản'
    }]
  },
  {
    prop: 'created_at',
    label: 'Ngày tạo',
    minWidth: 120,
    type: 'datetime'
  }
]
