<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="orders" :actions="actions" :paginate="paginate"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Message } from 'element-ui';
  import MyTable from 'src/components/UIComponents/Table.vue'
  import orderSchemas from './order-schemas'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import dtHelper from 'src/helpers/datatable'

  export default {
    components: {
      MyTable,
      MyFilter,
      Message
    },
    computed: {
      orders() {
        let rows = this.$store.state.orders.data || [];
        return rows;
      },
      paginate() {
        let item = this.$store.state.orders || {};
        item.type = 'orders';
        return item;
      }
    },
    data() {
      return {
        filterOutput: this.$store.state.currentFilterOrders || [],
        columnDefs: dtHelper.buildColumDefs(orderSchemas),
        actions: [
          {
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil',
            callback: this.edit
          }
        ],
        filter: {},
        rules: dtHelper.buildRules(orderSchemas),
      }
    },

    methods: {
      updateFilter(filterOutput) {
        this.$store.dispatch('setCurrentFilterOrders', filterOutput);
        this.filterOutput = filterOutput;
        let filterString = dtHelper.buildQueryString(filterOutput);
        this.$store.dispatch('fetchOrders', {
          filterString: filterString
        });
      },

      edit(index, row) {
        this.$router.push('/orders/' + row.id);
      }
    },

    mounted() {
      window.AP = this;
      if (this.filterOutput && this.filterOutput.length) {
        let filterString = dtHelper.buildQueryString(this.filterOutput);
        this.$store.dispatch('fetchOrders', {
          filterString: filterString
        });
      } else {
        this.$store.dispatch('fetchOrders');
      }
      this.$store.dispatch('setPageTitle', 'Quản lý đơn hàng');
      this.$store.dispatch('setCurrentActions', [])
      this.$store.dispatch('setCurrentFilterUsers', []);
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
